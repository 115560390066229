import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText } from '@prismicio/react';

import GetStartedButton from '../Buttons/ButtonGetStarted';
import WatchDemoButton from '../Buttons/ButtonWatchDemo';

import TrustedBrands from '../Elements/TrustedBrands';

const Hero = ({
  children,
  heading,
  sub_heading,
  button_text,
  button_link,
  image,
}) => (
  <>
    <div className="background1 w-full flex flex-row pt-24 lg:pt-36 relative lg:min-h-[48rem] lg:h-[100vh]">
      <div className="absolute right-0 top-0">
        <GatsbyImage image={getImage(image)} />
      </div>
      <div className="flex flex-col md:items-center justify-center gap-2 h-full z-10 px-4 w-full py-24">
        <PrismicRichText
          field={heading.richText}
          components={{
            heading1: ({ children }) => (
              <h1 className="lg:text-7xl md:text-5xl sm:text-4xl text-3xl text-blue font-medium tracking-tighter title1 relative md:text-center uppercase mb-8 max-w-5xl">
                {children}
              </h1>
            ),
          }}
        />
        <div>{children}</div>
        <p className="text-lg text-blue pb-3 md:text-center max-w-[32rem]">
          {sub_heading}
        </p>
        <div className="flex flex-row gap-4 flex-wrap">
          <GetStartedButton className="m-0" />
          <WatchDemoButton className="m-0" />
        </div>
        <div className="p-12"></div>
      </div>
    </div>
    <TrustedBrands
      className="sm:pb-20 pt-40 md:pt-24 pb-48 z-10"
      button_text={button_text}
      button_link={button_link}
    />
  </>
);

export default Hero;

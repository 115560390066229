import React from 'react';
import { graphql } from 'gatsby';

import { FormButton } from '../../frontend-shared/components';

import ArrowButton from '../Elements/ArrowButton';
import RichOrText from '../Elements/RichOrText';
import StripeSection from '../Elements/StripeSection';

import CarrierListContent from '../CarriersList/CarrierListContent';

const Content = ({
  slice: {
    primary: {
      title,
      description,
      cannot_find_carrier_text: { richText: cannotFindCarrierText },
      cannot_find_carrier_button_text,
      cannot_find_carrier_button_link,
    },
    items,
  },
}) => (
  <>
    <StripeSection className="py-16" title={title} description={description}>
     <ul className="grid grid-cols-1 sm:grid-cols-2 items-center gap-4 max-w-3xl mx-auto py-12">
       {items.map(({
         carrier: {
           document: {
             data: {
               name,
               slug,
               logo_colored,
               logo_dark,
             },
           },
         },
       }) => (
         <li className="bg-white rounded-lg tbox-shadow p-4 flex gap-4 items-center h-[80px] m-0">
           <div className="w-1/3">
             <img
               className="m-0 max-h-[40px] max-w-[80px]"
               src={logo_colored.fluid?.srcWebp || logo_dark.fluid?.srcWebp}
               srcset={logo_colored.fluid?.srcSetWbp || logo_dark.fluid?.srcSetWbp}
             />
           </div>
           <div className="text-center xl:text-xl lg:text-lg text-md text-blue">
             {name}
           </div>
           <div className="flex-grow" />
           {slug && (
             <a className="no-underline" href={slug}>
               <ArrowButton />
             </a>
           )}
         </li>
       ))}
     </ul>
     <div className="flex flex-col items-center flex gap-2 mb-12">
       <RichOrText
         content={cannotFindCarrierText}
         components={{
           paragraph: ({ children }) => (
             <p className="text-center xl:text-2xl lg:text-xl text-lg text-blue text-medium">
               {children}
             </p>
           ),
         }}
       />
       {cannot_find_carrier_button_text && (
         <a className="no-underline" href={cannot_find_carrier_button_link}>
           <FormButton color="primary" size="md">
             {cannot_find_carrier_button_text}
           </FormButton>
         </a>
      )}
     </div>
    </StripeSection>
    <CarrierListContent />
  </>
);

export default Content;

export const query = graphql`
  fragment carriersByCountryDeliveryServicesSlice on PrismicCarriersByCountryDataBodyDeliveryservices {
    id
    slice_type
    primary {
      title
      description { richText }
      cannot_find_carrier_text { richText }
      cannot_find_carrier_button_text
      cannot_find_carrier_button_link
    }
    items {
      carrier {
        document {
          ... on PrismicIntegration {
            data {
              name
              slug
              logo_dark {
                fluid(maxHeight: 360, maxWidth: 360) {
                  srcWebp
                  srcSetWebp
                }
              }
              logo_colored {
                fluid(maxHeight: 360, maxWidth: 360) {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

import React from 'react';
import { graphql } from "gatsby"

import CustomerExperience from '../Elements/CustomerExperience';
import FaqSection from '../Elements/FaqSection';

export default (props) => (
  <>
    <FaqSection {...props} />
    <div className="bg-gradient xl:py-32 md:py-24 py-16 text-white">
      <CustomerExperience items="clients" />
    </div>
  </>
);

export const query = graphql`
  fragment carriesByCountryFaqSlice on PrismicCarriersByCountryDataBodyFaqs{
    id
    slice_type
    items {
      faq {
        document {
          ... on PrismicFaq {
            id
            data {
              question {
                text
                html
              }
              answer {
                text
                html
                richText
              }
            }
          }
        }
      }
    }
  }
`

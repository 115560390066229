import React from "react"
import { graphql } from "gatsby"
import Hero from './Hero';

const HeroSection = ({ slice: { primary } }) => <Hero {...primary} />;

export default HeroSection;

export const query = graphql`
  fragment carriersByCountryHeroSlice on PrismicCarriersByCountryDataBodyHero {
    id
    slice_type
    primary {
      heading { richText }
      sub_heading
      button_text
      button_link
      image {
        gatsbyImageData(layout: FIXED)
      }
    }
  }
`
